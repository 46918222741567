<template>
  <div class="glo-content">
    <div class="title">馨佰拍管理后台</div>
    <div class="login-main shadow">
      <h3 class="login-title">管理员登录</h3>
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="handleLogin()" status-icon>
        <el-form-item prop="username">
          <el-input v-model="dataForm.username" placeholder="帐号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="dataForm.password" type="password" placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="login-btn-submit" type="primary" @click="handleLogin()">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Request from '@/api/admin';

export default {
  components: {},

  data() {
    return {
      dataForm: {
        username: '',
        password: '',
      },
      dataRule: {
        username: [{ required: true, message: '帐号不能为空', trigger: 'change' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'change' }],
      },
    };
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    // 提交表单
    handleLogin() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          Request.login(this.dataForm).then(res => {
            if(res && res.code === 200){
              localStorage.setItem('userName', this.dataForm.username || '');
              localStorage.setItem('token', res.data);this.$router.push('/console/car/sale')
            }
          })
        }
      });
    },
    
  },
};
</script>

<style lang="scss" scoped>
.glo-content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 20%;
  background: url('../assets/loginbg.png') no-repeat;
  background-size: 100%;
}

.title {
  width: 500px;
  position: absolute;
  left: 50%;
  bottom: 76%;
  margin-left: -250px;
  color: #ffffff;
  font-size: 52px;
  font-weight: bold;
  text-align: center;
}

.login-main {
  box-sizing: border-box;
  width: 500px;
  height: 320px;
  margin: 0 auto;
  padding: 39px 62px 0 62px;
  background: #f2f2f2;
  border-radius: 10px;
}

.login-title {
  margin: 20px 0;
  font-size: 22px;
  text-align: center;
}

.login-captcha img {
  width: 160px;
  height: 40px;
  border-radius: 4px;
}
.login-btn-submit {
  width: 100%;
}

.shadow {
  position: relative;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3), 0px 0px 20px rgba(0, 0, 0, 0.1) inset;
}

.shadow::before,
.shadow::after {
  content: '';
  position: absolute;
  z-index: -1;
}

.shadow::before,
.shadow::after {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 15px;
  left: 10px;
  width: 50%;
  height: 20%;
}

.shadow::before,
.shadow::after {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 15px;
  left: 10px;
  width: 50%;
  height: 20%;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
  transform: rotate(-3deg);
}
.shadow::after {
  right: 10px;
  left: auto;
  transform: rotate(3deg);
}
</style>
